import React from "react";
import menutrayssix from "../images/trays/menutrayssix.jpg";
import main from "../images/trays/main.jpg";
import sides from "../images/trays/sides.jpg";
import snacks from "../images/trays/snacks.jpg";
import desserts from "../images/trays/desserts.jpg";
import drinks from "../images/trays/drinks.jpg";
import trays from "../images/trays/trays.jpg";

const Menu = () => {
  return (
    <div>
      <div className="container">
        <div className="contain1">
        <div className="row align-items-center my-5">
            <div className="col-lg-6">
              <div className="containText">
                <p>
                  <br></br>
                </p>
                <center>
                <h1 className="font-weight-bold">
                  <b>📋 Menu 📋</b>
                </h1>
                </center>
                <div>
                <br></br>
              </div>
              <p>
                There are a variety of things to eat for lunch. Taco Day is on Tuesday and pizza slices are available every day, but the combination of sides and drinks is limitless. In this collection there are 10,000 Pixel Lunch Trays all with different combinations of items, colored trays, and background colors. The scarcity of each item has not been decided yet. 
                <br></br>
                <br></br>
                Jump into the lunch line so you can ask the lunch lady about how things are going in lunch lady land. Hoagies and grinders, hoagies and grinders, navy beans, navy beans, navy beans, meatloaf sandwich, sloppy joe, sloppy sloppy joe.
              </p>
              </div>
            </div>    
            <div className="col-lg-6">
              <br></br>
              
              <img
                className="img-fluid rounded mb-4 mb-lg-0"
                src={menutrayssix}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="contain2">
          <div className="row align-items-center my-5">
            <div className="col-lg-6">
              <div className="containText">
                <p>
                  <br></br>
                </p>
                <center>
                <h1 className="font-weight-bold">
                  <b>Trays </b>
                </h1>
                <div>
                  <br></br>
                </div>
                <p>
                    There are fifteen (15) different color options of trays::
                </p>
                <p>
                  <strong>Brown</strong>  &nbsp; &nbsp; {" "}
                  <strong>Beige</strong> &nbsp; &nbsp; {" "}
                  <strong>Light Yellow</strong> &nbsp; &nbsp; {" "}
                  <strong>Yellow</strong> &nbsp; &nbsp; {" "}
                  <strong>Sea Foam Green</strong>   
                  <p></p>
                  <strong>Green</strong>  &nbsp; &nbsp; {" "}
                  <strong>Teal</strong> &nbsp; &nbsp; {" "}
                  <strong>Cyan</strong> &nbsp; &nbsp; {" "}
                  <strong>Light Blue</strong> &nbsp; &nbsp; {" "}
                  <strong>Pale Blue</strong>  
                  <p></p>
                  <strong>Blue</strong>  &nbsp; &nbsp; {" "}
                  <strong>Dark Blue</strong> &nbsp; &nbsp; {" "}
                  <strong>Purple</strong> &nbsp; &nbsp; {" "}
                  <strong>Dark Red</strong> &nbsp; &nbsp; {" "}
                  <strong>Red</strong> 
                  
                  <p></p>
                </p>
                </center>
                <p></p>
                <p>
                  <br></br>
                </p>
              </div>
            </div>
            <div className="col-lg-6">
            <img
                className="img-fluid rounded mb-4 mb-lg-0"
                src={trays}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="contain1">
          <div className="row align-items-center my-5">
            <div className="col-lg-5"></div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="contain2">
          <div className="row align-items-center my-5">
            <div className="col-lg-6">
              <div className="containText">
                <p>
                  <br></br>
                </p>
                <center>
                <h1 className="font-weight-bold">
                  <b>Main Course </b>
                </h1>
                <div>
                  <br></br>
                </div>
                <p>
                    There are nine (9) different options for the main course:
                </p>
                <p>
                  <strong>Cheeseburger</strong>  &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                  <strong>Sandwich 1</strong> &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                  <strong>Sandwich 2</strong> 
                  <p></p>
                  <strong>Chicken Sandwich</strong> &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                  <strong>Pizza Slice</strong> &nbsp; &nbsp; &nbsp; &nbsp;{" "}    
                  <strong>Hotdog</strong>
                  <p></p>
                  <strong>Chicken Nuggets</strong> &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                  <strong>Corndog</strong> &nbsp; &nbsp; &nbsp; &nbsp;{" "}    
                  <strong>Tacos</strong>
                  <p></p>
                </p>
                </center>
                <p></p>
                <p>
                  <br></br>
                </p>
              </div>
            </div>
            <div className="col-lg-6">
            <img
                className="img-fluid rounded mb-4 mb-lg-0"
                src={main}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="contain1">
          <div className="row align-items-center my-5">
            <div className="col-lg-5"></div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="contain2">
          <div className="row align-items-center my-5">
            <div className="col-lg-6">
              <div className="containText">
                <p>
                  <br></br>
                </p>
                <center>
                <h1 className="font-weight-bold">
                  <b>Side Dishes </b>
                </h1>
                <div>
                  <br></br>
                </div>
                <p>
                    There are five (5) different options for the side dish:
                </p>
                <p>
                  <strong>French Fries</strong>  &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                  <strong>Tater Tots</strong> &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                  <strong>Green Beans</strong> 
                  <p></p>
                  <strong>Tomato Soup</strong> &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                  <strong>Mashed Potatos</strong>   
                 
                  <p></p>
                </p>
                </center>
                <p></p>
                <p>
                  <br></br>
                </p>
              </div>
            </div>
            <div className="col-lg-6">
            <img
                className="img-fluid rounded mb-4 mb-lg-0"
                src={sides}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="contain1">
          <div className="row align-items-center my-5">
            <div className="col-lg-5"></div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="contain2">
          <div className="row align-items-center my-5">
            <div className="col-lg-6">
              <div className="containText">
                <p>
                  <br></br>
                </p>
                <center>
                <h1 className="font-weight-bold">
                  <b>Vegetables and Snacks </b>
                </h1>
                <div>
                  <br></br>
                </div>
                <p>
                    There are eleven (11) different options for vegetables and snacks:
                    <br></br>
                    (NOTE: all chips are unbranded)
                </p>
                <p>
                  <strong>Potato Chips</strong>  &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                  <strong>BBQ Chips</strong> &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                  <strong>Cheese Puffs</strong> 
                  <p></p>
                  <strong>Chips 1</strong> &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                  <strong>Chips 2</strong> &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                  <strong>Chips 3</strong>   
                  <p></p>
                  <strong>Celery & Carrots</strong>  &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                  <strong>Lettuce & Tomato</strong> &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                  <strong>Peas & Carrots</strong> 
                  <p></p>
                  <strong>Broccoli</strong> &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                  <strong>Almonds</strong> 
                  
                  <p></p>
                </p>
                </center>
                <p></p>
                <p>
                  <br></br>
                </p>
              </div>
            </div>
            <div className="col-lg-6">
            <img
                className="img-fluid rounded mb-4 mb-lg-0"
                src={snacks}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="contain1">
          <div className="row align-items-center my-5">
            <div className="col-lg-5"></div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="contain2">
          <div className="row align-items-center my-5">
            <div className="col-lg-6">
              <div className="containText">
                <p>
                  <br></br>
                </p>
                <center>
                <h1 className="font-weight-bold">
                  <b>Desserts and Fruits </b>
                </h1>
                <div>
                  <br></br>
                </div>
                <p>
                    There are eight (8) different options for desserts and fruits:
                </p>
                <p>
                  <strong>Orange</strong>  &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                  <strong>Apple</strong> &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                  <strong>Grapes</strong> 
                  <p></p>
                  <strong>Donut</strong> &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                  <strong>Cookie</strong> &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                  <strong>Cherry Pie</strong>   
                  <p></p>
                  <strong>Rice Crispie Treat</strong>  &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                  <strong>Watermelon, Stawberry, & Orange</strong> 
                  
                  <p></p>
                </p>
                </center>
                <p></p>
                <p>
                  <br></br>
                </p>
              </div>
            </div>
            <div className="col-lg-6">
            <img
                className="img-fluid rounded mb-4 mb-lg-0"
                src={desserts}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="contain1">
          <div className="row align-items-center my-5">
            <div className="col-lg-5"></div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="contain2">
          <div className="row align-items-center my-5">
            <div className="col-lg-6">
              <div className="containText">
                <p>
                  <br></br>
                </p>
                <center>
                <h1 className="font-weight-bold">
                  <b>Drinks </b>
                </h1>
                <div>
                  <br></br>
                </div>
                <p>
                    There are six (6) different options for drinks:
                </p>
                <p>
                  <strong>Milk</strong>  &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                  <strong>Soda</strong> &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                  <strong>Orange Juice</strong> 
                  <p></p>
                  <strong>Beer</strong> &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                  <strong>Coffee</strong> &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                  <strong>Chocolate Milk</strong>   
                 
                  <p></p>
                </p>
                </center>
                <p></p>
                <p>
                  <br></br>
                </p>
              </div>
            </div>
            <div className="col-lg-6">
            <img
                className="img-fluid rounded mb-4 mb-lg-0"
                src={drinks}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="contain1">
          <div className="row align-items-center my-5">
            <div className="col-lg-5"></div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="contain1">
          <div className="row align-items-center my-5">
            <div className="col-lg-5">
              <p>
                <br></br>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
