import React from "react";
import menutrays from "../images/trays/menutrays.jpg";


const LunchLine = () => {
  return (
    <div>
      <div className="container">
        <div className="contain1">
        <div className="row align-items-center my-5">
            <div className="col-lg-6">
              <div className="containText">
                <p>
                  <br></br>
                </p>
                <center>
                    <h1 className="font-weight-bold">
                    <b>🥪 Lunch Line 🥪</b>
                    </h1>
                    <h2 className="font-weight-bold">
                    <b>🗺️ (Roadmap) 🗺️</b>
                    </h2>
                </center>
                <div>
                <br></br>
                </div>
                <p>
                Each of my NFT projects so far have served a purpose, either as proof of concepts or learning experiences. My roadmap for this project is simple: this Pixel Lunch Tray collection is meant to provide the community with fun artwork and to act as a stepping stone in better learning about Loopring's API. There are all sorts of fun ideas I have that I may incorporate later, but the following roadmap is a look at what I intend to accomplish.
                <br></br>
                <br></br>
                (NOTE: This roadmap is subject to change.)   
                </p>
              </div>
            </div>    
            <div className="col-lg-6">
              <br></br>
              
              <img
                className="img-fluid rounded mb-4 mb-lg-0"
                src={menutrays}
                alt=""
              />
            </div>
        </div>
        </div>
      </div>
      <div className="container">
        <div className="contain2">
              <div className="containText">
                <p>
                  <br></br>
                </p>
                <center>
                <h1 className="font-weight-bold">
                  <b>01 </b>
                </h1>
                <h2>Become a GameStop NFT Marketplace Creator</h2>
                </center>
                <div>
                  <br></br>
                </div>
                <p>
                   The first step in making in launching this project is being accepted as an official GameStop NFT Marketplace Creator. I sent in my application on Nov. 21, 2022 and will update this roadmap if I hear back. In the meantime, please check out the <a href="https://nft.gamestop.com/">GameStop NFT Marketplace</a>.
                </p>
                <p>
                  <br></br>
                </p>
              </div>
            </div>
            
      </div>
      <div className="container">
        <div className="contain1">
          <div className="row align-items-center my-5">
            <div className="col-lg-5"></div>
          </div>
        </div>
      </div>
      
      <div className="container">
        <div className="contain2">
              <div className="containText">
                <p>
                  <br></br>
                </p>
                <center>
                <h1 className="font-weight-bold">
                  <b>02 </b>
                </h1>
                <h2>Mint 10,000 unique NFTs</h2>
                </center>
                <div>
                  <br></br>
                </div>
                <p>
                    Getting all 10,000 images created randomly and uploaded to IPFS is easy. Creating all 10,000 corresponding JSON files made and uploaded to IPFS is just as simple. Actually minting the batch as one collection is the part I don't know how to do and excited to learn how it works on Loopring's Layer2 in GameStop's NFT Marketplace. 
                </p>
                <p>
                  <br></br>
                </p>
              </div>
            </div>
            </div>


            <div className="container">
        <div className="contain1">
          <div className="row align-items-center my-5">
            <div className="col-lg-5"></div>
          </div>
        </div>
      </div>
      
      <div className="container">
        <div className="contain2">
              <div className="containText">
                <p>
                  <br></br>
                </p>
                <center>
                <h1 className="font-weight-bold">
                  <b>03 </b>
                </h1>
                <h2>Update Website to Trigger Renders for NFT owners</h2>
                </center>
                <div>
                  <br></br>
                </div>
                <p>
                    Once the NFTs are minted, the website can then be updated so that the API call will look for the Pixel Lunch Tray token balance in user's wallet, which will ultimately trigger conditional renders across the website. These renders will allow users to access hidden pages and exclusive content.
                </p>
                <p>
                  <br></br>
                </p>
              </div>
            </div>
            </div>

            <div className="container">
        <div className="contain1">
          <div className="row align-items-center my-5">
            <div className="col-lg-5"></div>
          </div>
        </div>
      </div>
      
      <div className="container">
        <div className="contain2">
              <div className="containText">
                <p>
                  <br></br>
                </p>
                <center>
                <h1 className="font-weight-bold">
                  <b>04 </b>
                </h1>
                <h2>Airdrop</h2>
                </center>
                <div>
                  <br></br>
                </div>
                <p>
                    The airdrop will occur three (3) months after the launch on the marketplace. All owners of the Pixel Lunch Tray collections on the designated date will be eligible for an exclusive NFT that will be airdropped to their wallet. Community members are encouraged to hodl their airdrops.
                </p>
                <p>
                  <br></br>
                </p>
              </div>
            </div>
            </div>


            <div className="container">
        <div className="contain1">
          <div className="row align-items-center my-5">
            <div className="col-lg-5"></div>
          </div>
        </div>
      </div>
      
  
      
      <div className="container">
        <div className="contain2">
              <div className="containText">
                <p>
                  <br></br>
                </p>
                <center>
                <h1 className="font-weight-bold">
                  <b>05 </b>
                </h1>
                <h2>Final Update to Website for Airdrop Triggers</h2>
                </center>
                <div>
                  <br></br>
                </div>
                <p>
                    The website will be updated a final time so that the API calls will look for the airdropped NFT in the user's wallet, which will ultimately trigger conditional renders across the website. These renders will allow users to access hidden pages and exclusive content.
                </p>
                <p>
                  <br></br>
                </p>
              </div>
            </div>
            </div>

      
      <div className="container">
        <div className="contain1">
          <div className="row align-items-center my-5">
            <div className="col-lg-5">
              <p>
                <br></br>
              </p>
              <p>
                <br></br>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LunchLine;
