import React, { useState } from "react";

import tray2 from "../images/trays/tray2.png";
import tray1 from "../images/trays/tray1.png";

const Home = () => {
  const [over, setOver] = useState(false);

  return (
    <div className="home">
      <br></br>
      <center>
        <div>
          <img
            src={over ? tray2 : tray1}
            alt="Pixel Lunch Tray"
            onMouseOver={() => setOver(true)}
            onMouseOut={() => setOver(false)}
          />
        </div>
      </center>

      <div className="container">
        <center>
          <div className="contain1">
            <div className="col-lg-5">
              <p>Pixel Lunch Trays: The Website</p>
              <br></br>
              <center>
              
              </center>            
            </div>
          </div>
        </center>
      </div>
    </div>
  );
};

export default Home;
