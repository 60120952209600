import React from "react";
import { Link } from "react-router-dom";
import cafe from "../images/trays/cafe.jpg";
import cafetrays from "../images/trays/cafetrays.jpg";

const Cafeteria = () => {
  return (
    <div>
      <div className="container">
        <div className="contain1">
        <div className="row align-items-center my-5">
            <div className="col-lg-6">
              <div className="containText">
                <p>
                  <br></br>
                </p>
                <center>
                <h1 className="font-weight-bold">
                  <b>🍱 Cafeteria 🍱</b>
                </h1>
                </center>
                <div>
                <br></br>
              </div>
              <p>
                The Cafeteria is where you can sit and enjoy your lunch! Here you can find all the latest news and soon there will be an interactive experience where Pixel Lunch Tray owners will be able to walk around the lunchroom and gather info from the trays on the tables. 
             <br></br>
             <br></br>
             This space is currently under construction. 
              </p>

              </div>
            </div>    
            <div className="col-lg-6">
              <br></br>
              
              <img
                className="img-fluid rounded mb-4 mb-lg-0"
                src={cafetrays}
                alt=""
              />
           
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="contain2">

              <div className="containText">
                <p>
                  <br></br>
                </p>
                <center>
                <h1 className="font-weight-bold">
                  <b>Cafeteria is Under Construction </b>
                </h1>
                </center>
                <div>
                  <br></br>
                </div>
                <p>
                  All of the tables are empty because everyone is eating lunch in the quad while the cafeteria is under construction. This space will hopefully be available upon release of the collection or shortly after the launch. Until then, please eat your lunch elsewhere.
                </p>
                <p></p>
                
                <p>
                  <br></br>
                </p>
              </div>
          

            <center>
              <img
                className="img-fluid rounded mb-4 mb-lg-0"
                src={cafe}
                alt=""
              />
              </center>
              <br></br>
          </div>

          </div>

     
      <div className="container">
        <div className="contain1">
          <div className="row align-items-center my-5">
            <div className="col-lg-5"></div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="contain1">
          <div className="row align-items-center my-5">
            <div className="col-lg-5"></div>
          </div>
        </div>
      </div>
      
             
      <div className="container">
        <div className="contain1">
          <div className="row align-items-center my-5">
            <div className="col-lg-5">
              <p>
                <br></br>
              </p>
              <p>
                <br></br>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cafeteria;
